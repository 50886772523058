import crossFetch from 'cross-fetch'

export default async function fetchJson<T = unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> {
  let headers: HeadersInit = init?.headers || {}

  if (typeof window !== 'undefined') {
    if (window.localStorage.getItem(`token`)) {
      headers = {
        ...headers,
        Authorization: `Bearer ${window.localStorage.getItem(`token`)}`,
      }
    } else if (window.sessionStorage.getItem(`token`)) {
      headers = {
        ...headers,
        Authorization: `Bearer ${window.sessionStorage.getItem(`token`)}`,
      }
    }
  }

  // note: we need to support fetch in a Node environment
  const fetchFn = process.env.NODE_ENV === 'test' ? crossFetch : fetch

  const requestInit: RequestInit = {...init, headers}
  const response = await fetchFn(input, requestInit)

  // there is no response if the request was a delete
  if (requestInit.method === `DELETE` && response.ok) {
    return
  }

  if (requestInit.method !== `DELETE`) {
    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json()

    // response.ok is true when res.status is 2xx
    // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    if (response.ok) {
      return data
    }

    let message = data.message

    if (Array.isArray(data)) {
      message = data[0].defaultMessage || data[0].message
    }

    throw new FetchError({
      message: response.status === 404 ? `Not found` : message,
      statusCode: response.status,
      response,
      data: data || new Error(`No data available`),
    })
  }

  throw new FetchError({
    message: response.status === 404 ? `Not found` : response.statusText,
    response,
    statusCode: response.status,
    data: new Error(`No data available`),
  })
}

export class FetchError extends Error {
  response: Response
  statusCode: number
  data: {
    message: string
  }

  constructor({
    message,
    response,
    data,
    statusCode,
  }: {
    message: string
    response: Response
    statusCode: number
    data: {
      message: string
    }
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError)
    }

    this.name = 'FetchError'
    this.response = response
    this.statusCode = statusCode
    this.data = data ?? {message: message}
  }
}
