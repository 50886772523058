
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import lightTheme from '@bishop/core-ui/core-theme/light-theme'
import {CacheProvider, EmotionCache} from '@emotion/react'
import {ThemeProvider} from '@mui/material/styles'
import type {AppProps} from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import {SWRConfig} from 'swr'
import createEmotionCache from '../lib/create-emotion-cache'
import fetchJson from '../lib/fetch-json'
import DialogProvider from '../providers/dialog-provider'

const PageProgressBar = dynamic(() => import('../components/page-progress-bar'))

const clientSideEmotionCache = createEmotionCache()

export type CompetencyInventoryAppProps = AppProps & {
  /**
   * Emotion cache provided by the framework.
   */
  emotionCache?: EmotionCache
}

const __Page_Next_Translate__ = function CompetencyInventoryApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: CompetencyInventoryAppProps) {
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="msapplication-TileColor" content="#3b82f6" />
        <meta name="theme-color" content="#3b82f6" />
        <meta name="application-name" content="Competency Inventory" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="apple-mobile-web-app-title"
          content="Competency Inventory"
        />
      </Head>

      <PageProgressBar
        color="#00e677"
        height={3}
        startPosition={0.3}
        options={{showSpinner: false}}
      />

      <SWRConfig
        value={{
          fetcher: fetchJson,
          revalidateOnFocus: false,
        }}
      >
        <ThemeProvider theme={lightTheme}>
          <DialogProvider>
            <Component {...pageProps} />
          </DialogProvider>
        </ThemeProvider>
      </SWRConfig>
    </CacheProvider>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  