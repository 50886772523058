import CoreActivityIndicator from '@bishop/core-ui/core-activity-indicator'
import CoreConfirmationDialog from '@bishop/core-ui/core-confirmation-dialog'
import CoreDialog from '@bishop/core-ui/core-dialog'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import {
  BaseSyntheticEvent,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react'
import DialogContext from '../../contexts/dialog-context'
import type {CommonDialogProps, DialogType} from '../../types/dialog.types'
import type {Template} from '../../types/template.types'

export type DialogProviderProps = PropsWithChildren<unknown>

/**
 * Time it takes to show or hide the dialog.
 */
const DIALOG_TRANSITION_DURATION = 250

const dialogLoadingIndicator = (
  <Box sx={{display: `grid`, justifyContent: `center`, p: 3}}>
    <CoreActivityIndicator id="dialog-loading" aria-label="Dialog loading" />
  </Box>
)

const CreateTemplateDialog = dynamic(
  () => import('../../components/dialogs/create-template-dialog'),
  {
    loading: () => dialogLoadingIndicator,
  },
)

const EditTemplateDialog = dynamic(
  () => import('../../components/dialogs/edit-template-dialog'),
  {
    loading: () => dialogLoadingIndicator,
  },
)

const DeleteTemplateDialog = dynamic(
  () => import('../../components/dialogs/delete-template-dialog'),
  {
    loading: () => dialogLoadingIndicator,
  },
)

const CreateSkillSetDialog = dynamic(
  () => import('../../components/dialogs/create-skill-set-dialog'),
  {
    loading: () => dialogLoadingIndicator,
  },
)

const UnlinkTemplateDialog = dynamic(
  () => import('../../components/dialogs/unlink-template-dialog'),
  {
    loading: () => dialogLoadingIndicator,
  },
)

const LinkTemplateDialog = dynamic(
  () => import('../../components/dialogs/link-template-dialog'),
  {
    loading: () => dialogLoadingIndicator,
  },
)

export default function DialogProvider({children}: DialogProviderProps) {
  const {t} = useTranslation(`common`)
  const [dialogType, setDialogType] = useState<DialogType>()
  const [payload, setPayload] = useState<unknown>()
  const [open, setOpen] = useState(false)
  const [isActiveFormDirty, setIsActiveFormDirty] = useState(false)
  const [showDirtyConfirmation, setShowDirtyConfirmation] = useState(false)

  function changeDialogType(dialogType: DialogType, payload?: unknown) {
    setDialogType(dialogType)
    setPayload(payload)
    setOpen(true)
  }

  async function clearDialogWithDirtyGuard(event: BaseSyntheticEvent) {
    if (isActiveFormDirty && event?.type !== 'submit') {
      return setShowDirtyConfirmation(true)
    }

    return clearDialog()
  }

  function clearDialog() {
    setIsActiveFormDirty(false)
    setShowDirtyConfirmation(false)
    setOpen(false)
  }

  const commonDialogProps: CommonDialogProps = {
    onClose: clearDialogWithDirtyGuard,
    onDirtyStateChange: useCallback(
      (isDirty: boolean) => setIsActiveFormDirty(isDirty),
      [],
    ),
    payload,
  }

  return (
    <DialogContext.Provider
      value={{
        dialogType,
        changeDialogType,
        clearDialog: clearDialogWithDirtyGuard,
      }}
    >
      <CoreConfirmationDialog
        aria-describedby="dirty-dialog-content"
        title={t(`dialog.dirtyConfirm.title`)}
        open={showDirtyConfirmation}
        titleProps={{sx: {pb: 0}}}
        contentProps={{id: `dirty-dialog-content`, sx: {py: 0}}}
        cancelButtonLabel={t(`form.cancel`)}
        confirmButtonLabel={t(`form.confirm`)}
        onClose={() => setShowDirtyConfirmation(false)}
        onConfirm={clearDialog}
        TransitionProps={{
          onExited: () => {
            setDialogType(undefined)
            setPayload(undefined)
          },
        }}
        maxWidth="xs"
        fullWidth
      >
        <Trans
          i18nKey="common:dialog.dirtyConfirm.description"
          components={{Typography: <Typography sx={{my: 1}} />}}
        />
      </CoreConfirmationDialog>

      <CoreDialog
        title={dialogType ? t(`dialog.title.${dialogType}`) : ''}
        open={open}
        onClose={clearDialogWithDirtyGuard}
        TransitionProps={{
          onExited: () => {
            setDialogType(undefined)
            setPayload(undefined)
          },
        }}
        maxWidth="md"
        fullWidth
      >
        {dialogType === 'CREATE_TEMPLATE' && (
          <CreateTemplateDialog {...commonDialogProps} />
        )}

        {dialogType === 'EDIT_TEMPLATE' && (
          <EditTemplateDialog
            {...commonDialogProps}
            payload={payload as Template}
          />
        )}

        {dialogType === 'DELETE_TEMPLATE' && (
          <DeleteTemplateDialog
            {...commonDialogProps}
            payload={payload as Template}
          />
        )}

        {dialogType === 'CREATE_SKILL_SET' && (
          <CreateSkillSetDialog
            {...commonDialogProps}
            payload={payload as Template}
          />
        )}

        {dialogType === 'UNLINK_TEMPLATE' && (
          <UnlinkTemplateDialog
            {...commonDialogProps}
            payload={
              payload as [Pick<Template, 'id' | 'name'>, Pick<Template, 'name'>]
            }
          />
        )}

        {dialogType === 'LINK_TEMPLATE' && (
          <LinkTemplateDialog
            {...commonDialogProps}
            payload={payload as string}
          />
        )}
      </CoreDialog>

      {children}
    </DialogContext.Provider>
  )
}
