module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  extensionsRgx: /\.page\.tsx$/,
  pages: {
    '*': ['common'],
    '/login': ['login'],
    '/reset-password': ['reset-password'],
    '/': ['dashboard'],
    '/templates': ['templates'],
    '/templates/[id]': ['templates'],
  },
}
