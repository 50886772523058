import {BaseSyntheticEvent, createContext} from 'react'
import type {DialogType} from '../../types/dialog.types'

export type DialogContextProps = {
  /**
   * Hides the current dialog.
   */
  clearDialog: (event: BaseSyntheticEvent) => Promise<void>
  /**
   * Changes currently active dialog's type or show a new dialog with the given
   * type.
   *
   * @param dialogType Dialog type
   * @param payload Additional payload to pass to the dialog
   */
  changeDialogType: <T = unknown>(dialogType: DialogType, payload?: T) => void
  /**
   * Currently active dialog type.
   */
  dialogType?: DialogType
}

export default createContext<DialogContextProps>({
  clearDialog: () => {
    return undefined
  },
  changeDialogType: () => {
    return undefined
  },
})
