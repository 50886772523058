import { createTheme } from '@mui/material/styles';
import { createStyled } from '@mui/system';
var lightTheme = createTheme({
    palette: {
        primary: {
            main: "#1471eb",
            dark: "#1266d3",
            light: "#2c7fed"
        },
        secondary: {
            main: "#00e677",
            dark: "#00cc68",
            light: "#00ff82"
        },
        background: {
            default: "#edf2f7"
        }
    },
    shape: {
        borderRadius: 4
    }
});
export var styled = createStyled({
    defaultTheme: lightTheme
});
export default lightTheme;
